.login-header {
   background: transparent;
   backdrop-filter: blur(0);
   padding-top: 38px !important;
}

.logincontent {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   /* height: calc(100vh - 100px); */
   color: #fff;
   border-radius: 10px;
   text-align: center;
   padding: 60px 64px 130px;
   margin-top: auto;
   overflow: hidden;
   min-height: 730px;
}

.logincontent .h6 {
   letter-spacing: -0.64px;
   font-size: 32px;
   background: linear-gradient(274deg, rgba(255, 255, 255, 0) -76.6964%, rgb(255, 255, 255) 100%);
   font-family: "Inter Tight", "Inter Tight Placeholder", sans-serif;
   -webkit-background-clip: text;
   width: fit-content;
   margin: 0 auto;
   -webkit-text-fill-color: transparent;
   font-weight: 400;
    margin-bottom: 5px;
}

.logincontent .signin-form-fields {
   gap: 0px;
}

.logincontent .title-desc {
   font-size: 16px;
   font-weight: 400;
   margin-bottom: 16px;
}

.logincontent p {
   font-size: 24px;
   margin-bottom: 20px;
}

.logincontent button {
   margin-bottom: 20px;
}

.back-to-home-link {
   display: flex;
   align-items: center;
   gap: 8px;
   font-size: 14px;
}
.back-to-home-link .arrow-left {
   transition: all 0.3s ease-in-out;
}

.back-to-home-link:hover .arrow-left {
   transform: translateX(-4px);
}

.logincontent .signup-text {
   font-size: 16px;
   font-weight: 600;
   color: rgb(252 252 252 / 0.7);
}
.logincontent .signup-text a {
   color: #fff;
   font-weight: 500;
}
.logincontent .signup-text a:hover {
   text-decoration: underline;
   text-underline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 768px) {
   .logincontent .signup-text {
      font-size:14px;
   }
   .logincontent .h6 {
      font-size:28px;
   }
   .logincontent {
      height: auto;
      padding: 20px 5px;
      min-height:650px!important;
   }
   .logincontent .title-desc {
      font-size:14px;
   }
   .banner-form-sec .form-group {
      width: 100%;
   }
   .banner-form-sec .form-control {
      min-width: auto;
      width: 100%;
   }
}

.or-sec-login {
   max-width: 350px;
   width: 100%;
   position: relative;
   margin-left: auto;
   margin-right: auto;
   font-size: 14px;
    color: lightgray;
    margin-top: -5px !important;
    margin-bottom: -8px !important;
    font-weight: 400;
}
.or-sec-login:before {
   content: "";
   width: calc(50% - 30px);
   height: 1px;
   background-color: rgb(255, 255, 255, 0.2);
   position: absolute;
   left: 0;
   top: 50%;
}
.or-sec-login:after {
   content: "";
   width: calc(50% - 30px);
   height: 1px;
   background-color: rgb(255, 255, 255, 0.2);
   position: absolute;
   right: 0;
   top: 50%;
}

/* #root {
    margin: 100px auto;
    max-width: 500px;
    padding-bottom: 100px;
  } */

.card {
   font-size: 164px;
   /* width: 100;
    height: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(100, 216, 22);
    */
   box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075), 0 0 4px hsl(0deg 0% 0% / 0.075),
      0 0 8px hsl(0deg 0% 0% / 0.075), 0 0 16px hsl(0deg 0% 0% / 0.075);
   transform-origin: 10% 60%;
}

.card-container {
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   padding-top: 20px;
   margin-bottom: -120px;
}

.splash {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   top: 0;
   clip-path: path(
      "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
   );
}
.loginbtn {
   background: linear-gradient(277.1367deg, #0d0d0d -17.5195%, #4f4f4f 273.526%) !important;
    border-radius: 5px;
    display: block;
    padding: 16px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 550;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important;
}
input:-webkit-autofill{
    -webkit-text-fill-color: white !important;
   border: 1px solid black;
}
