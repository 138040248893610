.container11 {
   text-align: center;
   font-family: Arial, sans-serif;
   padding: 20px;
   border-radius: 10px;
}
.icon1 {
   font-size: 40px;
   margin-bottom: 0px;
   display: block;
    width: 50px;
    height: auto;
    mask-image: linear-gradient(90deg, #000, #0000);
-webkit-mask-image: linear-gradient(90deg, #000, #0000);
    margin-left: auto;
    margin-right: auto;
}
.title {
   font-size: 24px;
   margin-bottom: 10px;
}
.subtitle {
   font-size: 16px;
}
.resource-banner {
   text-align: center;

   font-family: Arial, sans-serif;
   background-color: rgb(8, 8, 8, 0.5);
   border-top: 1px solid rgba(255, 255, 255, 0.1);
   padding: 20px;
   width: auto;
}

.resource-col-main {
   max-width: 1500px;
   margin-inline: auto;
}

.enterprise-block-main {
   background-color: rgb(8, 8, 8);
   margin-top:30px!important;
}
.resource-banner1 {
   text-align: center;
   font-family: Arial, sans-serif;
   padding: 20px 0px 0px;
   background-color: rgb(10 10 10);
   border-radius:5px;
   /* width: 80%; */
}

.vertical-block .btm {
   height: 0px;
   transition: all 0.5s;
   opacity: 0;
}
/* .vertical-block.active .btm{
    height: 140px;transition: all 0.5s;opacity: 1;
} */
.vertical-slider.image-slider .vertical-block img {
   display: none;
   position: absolute;
   width: 50%;
   height: 100%;
   object-fit: cover;
   right: 0;
   top: 0;
   border-radius: 30px;
}
.vertical-slider.image-slider .vertical-block.active img {
   display: block;
}
.banner-resources {
   padding-top: 80px;
   position: relative !important;
}

.resource-banner-title {
   color: #8e9096;
   font-weight:400;
}

.banner-resources .h1 {
   letter-spacing: -1.08px;
   font-weight: 400;
   margin-left:-10px;
   margin-right:-10px;
}
/* .banner-resources .container{
    max-width: 1440px;
} */
.vertical-slider .vertical-block h4 {
   font-size: 24px;
   letter-spacing: -0.48px;
   font-weight: 400;
}
.vertical-slider .vertical-block {
   padding: 20px;
   border-radius: 30px;
}
.vertical-slider .vertical-block.active {
   background-color: rgb(255, 255, 255, 0.06);
}
.vertical-slider .vertical-block.active h4 {
   color: #f0f0f0;
}
.resources-slider .container {
   max-width: 1220px;
}
.banner-resources h1,
.resource-banner1 h2 {
   /* background: -webkit-linear-gradient(#eee, #333); */
   background: linear-gradient(0deg, rgba(255, 255, 255, 0) -77.1856%, rgb(255, 255, 255) 121.843%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.resource-banner1 h2 {
   background-image: linear-gradient(278deg, rgba(255, 255, 255, 0) -77.1856%, rgb(255, 255, 255) 72.4947%);
   letter-spacing: -0.02em;
}

.banner-resources .title {
   font-size: 20px;
   width: fit-content;
   margin: 0 auto;
   margin-bottom:7px;

   background: -webkit-linear-gradient(left, #efefef, #999);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   font-weight: 550;
}
.more-settings {
   width: 250px;
}
.p-16 {
   font-size:16px;
}
.p-18 {
   font-size:18px;
   color:#75777c;
}
.itemdesc {
   color:#75777c;
}
.thinbtn {
   padding: 7px 12px !important;
}
.agencybanner {
   background-color:transparent;
}
.vertical-block.active .agencytitle {
   background: linear-gradient(to right, #fff, #444) !important;
   -webkit-background-clip: text!important;
   color: transparent!important;
   display: inline-block; /* Ensure the gradient spans only the text, but full-width */
}
.agencybtn {
   background: linear-gradient(to right, #ccc, #444) !important;
    -webkit-background-clip: text!important;
    color: transparent!important;
   background-color:#141414!important;
   border: 1px solid #2C2C2C!important;
}
.mobileonly {
   display:none;
   }
   .resourcessettings {
      padding-top:50px;
      }
@media (min-width: 1200px) {
   .text-start{
      margin-bottom:50px!important;
   }
   .resources-slider {
      margin-top:0px!important;
   }
   .banner-resources h1 {
      font-size: 54px;
   }
   .banner-resources .cnt2 {
      max-width: 1540px;
   }
   .resource-banner1 h2 {
      font-size: 40px;
   }
}
@media (min-width: 1250px) {
.firstsectionre {
   padding-left:150px;
   padding-right:150px;
}
   .threeicons {
   padding-left:30px;
   padding-right:30px;
      margin-top:50px;
      margin-bottom:50px;
}
   .resource-banner1 {
   padding-left:30px;
   padding-right:30px;
}
}
@media (min-width: 1500px) {
   .threeicons {
   padding-left:50px;
   padding-right:50px;
      margin-top:70px;
      margin-bottom:70px;
}
   .resource-banner1 {
   padding-left:50px;
   padding-right:50px;
}
}
@media (max-width: 767px) {
   .icon1 {
      margin-bottom:0px;
   }
   .echteicon1{
   margin-top:40px;
}
   .enterprise-block-main {
   margin-bottom:0px;
}
   .agencybanner {
   margin-top:-40px;
}
   .mobile-image-slider {
   width:100%;
      height:200px;
      border-radius:15px;
}
   .vertical-slider .vertical-block h4 {
   font-size:20px;
}
   .threeicons {
   padding-left:10px;
   padding-right:10px;
   padding-top:0px!important;
}
   .vertical-slider.image-slider .vertical-block img {
      position: relative;
      height: 300px;
      width: 100%;
   }
   .mobileonly {
   display:block;
   width: 100%; /* Ensure the image fits the width of the container */
      height: auto; /* Keep aspect ratio for the image */
      margin: 0; /* Remove unwanted margins */
      border: none; /* No borders */
   }

   .vertical-slider.image-slider .vertical-block img {
      width: 100%; /* Ensure full-width image */
      height: 200px; /* Maintain rectangular aspect ratio */
   }
   .desktoponly {
   display:none;
   }
   
.p-16 {
   font-size:14px;
}
   .p18 {
   font-size:16px;
}
   .firstsectionre {
   padding-left:25px;
   padding-right:25px;
   margin-top:-20px;
   }
   .mobiletitle {
   margin-top:-30px;
   }
   .banner-resources .title {
   font-weight:550;
   font-size:18px;
   }
   .container11 {
   padding-top:0px!important;
   }
   .block-image
   {
   height: 200px;
      width:95%;
      object-fit: cover; 
      margin-bottom:25px;
      margin-top:5px;
      border-radius:15px;
   }
   .resource-banner1 {
      padding:20px 0px 0 !important;
      background-color:transparent!important;
   }
}
