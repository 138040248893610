.signup-title {
   font-size: 28px;
   font-weight: 400;
}

.or-sec-login.signup {
   margin-block: 0 !important;
}

.signup-text.signup {
   color: rgb(252 252 252 / 0.7);
}

.signup-text.signup a {
   color: #fff;
}
