.listbullets {
    list-style-type: disc!important; /* Ensures bullets are shown */
    margin-left:20px;
}
.informativesection {
    text-align:left;
    margin:60px 20px;
    margin-bottom:40px;
}
.agencytitle{
    font-size:20px;
    color:#f0f0f0;
    margin-bottom:25px;
}
.infosec1{
    margin-top:70px;
    background-color: #0f0f0f;
    text-align:left;
    padding: 40px 30px;
    position:relative;

}
.headlineblog2 {
    margin-top:-10px;
}
.nextblogbtn {
    padding:6px;
    padding-left:10px;
    padding-right:10px;
    border-radius:5px;
color:gray;
    background-color: #0f0f0f;
    border:1px solid #2c2c2c!important;
    margin-bottom:10px;
}
.moreblogsitem {
    margin-top:40px;
}
.moreblogssec{
    margin-bottom:50px;
    margin-left:25px;
    margin-right:25px;
}
.learnmoreabout{
   margin-top:-20px;
   margin-bottom:20px;
   margin-left:5px;
}
.moreblogsitemfirst{
    padding-top:5px;
 }
 .btnzehma{
    margin-bottom:25px;
 }
 .littlarrow {
    margin-left:4px;
    margin-top:-3px;
 }
 .newagencytitle{
 margin-bottom:15px;
 }
 .newitemdesc{
 margin-bottom:0px;
 }
 .newenterpriseblock{
 padding-bottom:40px!important;
 }

.scroll-arrow-container {
    position: absolute;
    bottom: -7px; /* Adjust distance from the bottom */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-arrow {
    display: inline-block;
    position: relative;
    font-size: 20px; /* Adjust size to make it thinner */
    color: #ffffff; /* Arrow color */
    text-shadow: 0 0 3px rgb(255, 255, 255), 0 0 5px #fff, 0 0 18px #fff; /* Subtle glowing effect */
    transform: rotate(90deg); /* Rotate to point up as an upside-down 'V' */
    animation: pulse 1.5s infinite;
    cursor: pointer; /* Optional: makes it look clickable */
}

.scroll-arrow::before {
    content: '';
    position: absolute;
    bottom: 0px; /* Adjust position */
    right:-25px;
    width: 70px; /* Adjust width for the semi-circle */
    height: 30px; /* Half of the width for a semi-circle */
    background-color: #000; /* Background color for the half-circle */
    border-top-left-radius: 60px; /* Half of the width for rounded corners */
    border-top-right-radius: 60px;
    z-index: -1; /* Ensures the half-circle is behind the arrow */
    transform: rotate(270deg);
}

@keyframes pulse {
    0%, 100% {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff;
    }
    50% {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff;
    }
}

@media only screen and (min-width: 750px) {
    .moreblogssec {
        width:75%;
        margin-left:auto;
        margin-right:auto;
    }
    .itemsvoordesktopcolumn {
        columns:2;
        overflow:hidden;
        gap:100px;
    }
    .firstitemsecondcolumn{
        padding-top:47px;
    }
    .dekstoplearnmoreabout{
        background-color: transparent;
        padding-top:0px;
    }
      .moreblogssec{
        margin-top:50px;
      }
      .infosec1 {
        padding:100px;
        margin-left:auto;
        margin-right:auto;
      }
      .informativesection{
        padding:50px 100px;
        margin-left:auto;
        margin-right:auto;
      }
      .containersec1{
        width:1200px!important;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
      }
      .containersec2{
        width:1200px!important;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
      }
}

@media only screen and (max-width: 750px) {

.itemsvoordesktopcolumn {
    display:none;
    }
}
