@media (min-width: 0px) and (max-width: 575px) {
    .top-title {
        font-size: 12px;
        margin-bottom: 45px !important;
    }
    .banner h1 {
        font-size: 30px;
        font-weight: 400;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .top-title {
        font-size: 14px;
    }
    .banner h1 {
        font-size: 40px;
    }
    .banner-form-sec {
        max-width: 80%;
        margin: 0 auto;
    }
}

@media (min-width: 768px) and (max-width: 1251px) {
    .container{
        max-width: 996px !important;
    }
}

@media (min-width: 992px) and (max-width: 1440px) {
}
@media (min-width: 992px) and (max-width: 8000px) {
}
