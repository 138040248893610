@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.table-container {
   width: 100%;
   overflow-x: auto;
}

.transparent-table {
   width: 100%;

   border-color: rgba(255, 255, 255, 0.08);
   backdrop-filter: blur(10px);
   /* Blur effect */
   -webkit-backdrop-filter: blur(10px);
   /* For Safari support */
}

.Pricing-sec h1 {
   background: linear-gradient(0deg, rgba(255, 255, 255, 0) -77.1856%, rgb(255, 255, 255) 121.843%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.pricingdesc {
   color:#545558;
}
.pricingamount {
   background: -webkit-linear-gradient(left, #fff, #666);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   font-weight:400;
   
}



.transparent-table th,
.transparent-table td {
   border: 1px solid rgba(255, 255, 255, 0.08);

   text-align: left;
}

.transparent-table {
   margin-top: 0px;
   padding: 60px 0px 130px 0px;
   background-image: url(https://localhost:3000/static/media/bg.e38cdecc16a270a0ecf7.png);
   background-size: 25%;
   background-position: center bottom;
   background-repeat: no-repeat;
}
.transparent-table .table-inner {
   border-radius: 16px;
   overflow: hidden;
   border: 1px solid rgba(255, 255, 255, 0.08);
}

.table-inner tr:first-child th {
   border-top: none;
}
.table-inner tr th:first-child {
   border-left: none;
}
.table-inner tr th:last-child {
   border-right: none;
}
.table-inner tr:last-child td {
   border-bottom: none;
}
.table-inner tr td:first-child {
   border-left: none;
}
.table-inner tr td:last-child {
   border-right: none;
}

.transparent-table table {
   backdrop-filter: blur(7.5px);
   background-color: rgba(31, 31, 31, 0.2);
}

.transparent-table th,
.transparent-table td {
   padding: 20px;
}

.transparent-table td:not(:first-child) {
   text-align: center;
   font-size: 15px;
   font-weight: 500;
   color: white;
}

.transparent-table th:not(:first-child) {
   text-align: center;
}

.space-mr-m {
   margin: 90px 0px;
}

.img-sc img {
   width: 70px;
   height: 70px;
   border-radius: 50%;
}

.question-sc {
   padding-bottom: 175px;
}
.testimonial-block {
   padding: 50px;
   border-radius: 20px;
   background-color: rgba(15, 15, 15);
}

.question-sc .inner-sc {
   padding: 32px;
   border-radius: 20px;
   background-color: rgba(15, 15, 15);
   max-width: 720px;
   margin: auto;
   display: flex;
flex-direction: column;
gap: 32px;
}

.testimonial-block .profile-sc .img-sc {
   width: 64px;
   height: 64px;
}
.testimonial-block .profile-sc .img-sc img {
   height: 64px;
   width: 64px;
   object-fit: cover;
}

.accordion,
.accordion * {
   background-color: transparent;
}

.accordion-button:focus,
button:focus {
   outline: none !important;
   box-shadow: none !important;
}

.accordion-button {
   color: white;
   font-weight: 500;
   padding: 25px 0px;
}

.accordion-item,
.accordion-flush > .accordion-item:last-child {
   border-bottom: 1px solid #0f0f0f;
}

.accordion-body {
   padding-top: 0;
   color: #8e9096;
   padding-left: 0;
   padding-right: 0;
}

.accordion-button:not(.collapsed) {
   box-shadow: none;
   color: white;
   background-color: transparent;
}

.accordion-button::after {
   background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2022%2022%22%3E%3Cpath%20d%3D%22M%2011%207%20L%2011%2015%20M%207%2011%20L%2015%2011%20M%2021%2011%20C%2021%2016.523%2016.523%2021%2011%2021%20C%205.477%2021%201%2016.523%201%2011%20C%201%205.477%205.477%201%2011%201%20C%2016.523%201%2021%205.477%2021%2011%20Z%22%20fill%3D%22transparent%22%20stroke-width%3D%221.2%22%20stroke%3D%22rgba(97%2C%2097%2C%2097%2C%200.8)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%22%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

.accordion-button:not(.collapsed)::after {
   background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2022%2022%22%3E%3Cpath%20d%3D%22M%207%2011%20L%2015%2011%20M%2021%2011%20C%2021%2016.523%2016.523%2021%2011%2021%20C%205.477%2021%201%2016.523%201%2011%20C%201%205.477%205.477%201%2011%201%20C%2016.523%201%2021%205.477%2021%2011%20Z%22%20fill%3D%22transparent%22%20stroke-width%3D%221.2%22%20stroke%3D%22rgba(97%2C%2097%2C%2097%2C%200.8)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%22%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

.question-sc h5 {
   font-size: 18px;
   font-weight: 500;
   color: white;
}
@media only screen and (max-width: 600px) {
   .question-sc h5 {
      font-size:16px;
   }
}
.question-sc .img-sc img {
   width: 56px;
   height: 56px;
   border: 1px solid white;
   margin-bottom:-10px;
}

.question-sc .mdl {
   margin-left: -15px;
   margin-right: -15px;
   position: relative;
   margin-top: -5px;
}

/* TestimonialSlider.css */
.testimonial-slider .slick-slide {
   display: flex;
   justify-content: center;
   align-items: center;
   height: auto;
   transition: transform 0.5s ease;
}

.testimonial-slider .slick-list {
   overflow: visible !important;
}

.testimonial-slider {
   width: 70%;
   margin: auto;
   padding-bottom: 80px;
}

.reviewdesc {
   color:#7F7F82;
}

.testimonial-sc .container-fluid {
   /* width: calc(100% - 80px); */
   margin: auto;
}

.testimonial-sec-title-main {
   padding-bottom: 30px;
}

.testimonial-sc .h1 {
   font-size: 48px;
   font-weight: 400;
   letter-spacing: -0.96px;
   max-width: 635px;
   margin-inline: auto;
   background: linear-gradient(280deg, rgba(255, 255, 255, 0) -66.5884%, rgb(255, 255, 255) 95.0568%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.testimonial-slider-main .swiper {
   padding-bottom: 60px;
   margin-top:-20px;
}

.testimonial-slider-main .testimonial-block {
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.testimonial-slider-main .swiper-pagination-bullet {
   height: 10px;
   width: 10px;
   background: #fcfcfc;
   transition: all 0.3s ease-in-out;
}
.testimonial-slider-main .swiper-pagination-bullet.swiper-pagination-bullet-active {
   background: white;
}

.testimonial-slider-main .testi-title {
   font-size: 26px;
   font-weight: 400;
   letter-spacing: -0.64px;
   line-height: 40px;
   margin-bottom: 0;
   background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) -77.0942%, rgb(255, 255, 255) 122.029%);
   -webkit-text-fill-color: transparent;
   -webkit-background-clip: text;
}

.testimonial-slider-main .profile-sc {
   gap: 28px;
}
.testimonial-slider-main .person-name {
   font-size: 18px;
   font-weight: 500;
   color: #fff;
}
.testimonial-slider-main .person-designation {
   font-size: 16px;
   font-weight: 400;
   color: #fcfcfc;
}
/* 
.slick-center .testimonial-block {
   transform: scale(1.1);
   transition: all 0.5s;
} */

.transparent-table .gradient {
   content: "";
   background: linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%);
   height: 2px;
   position: absolute;
   top: -1px;
   width: 100px;
   z-index: 1;
}

.transparent-table .gradient-bottom {
   background: linear-gradient(360deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 88.2461%);
   flex: 0 0 auto;
   height: 100px;
   overflow: hidden;
   position: absolute;
   right: -1px;
   top: -4px;
   width: 2px;
   z-index: 1;
}

.transparent-table:after {
   content: "";
   width: 190px;
   height: 140px;
   background-image: url(/public/images/rgt.png);
   background-repeat: no-repeat;
   position: absolute;
   right: -100px;
   background-size: contain;
   top: -0px;
   z-index: -1;
}
.transparent-table::before {
   content: "";
   width: 200px; /* Adjust width to the actual width of bg.png */
   height: 200px; /* Adjust height based on image dimensions */
   background-image: url(/public/images/bg.png);
   background-repeat: no-repeat;
   background-size: contain;
   position: absolute;
   left: 50%; /* Centers horizontally */
   bottom: 30px; /* Moves half of the image height below the table */
   transform: translateX(-50%); /* Centers the image */
   z-index: -1; /* Keeps it behind the table */
}

.transparent-table2:after {
   content: "";
   width: 190px;
   height: 140px;
   background-image: url(/public/images/rgt.png);
   background-repeat: no-repeat;
   position: absolute;
   right: -100px;
   background-size: contain;
   top: -0px;
   z-index: -1;
}
.transparent-table2::before {
   content: "";
   width: 200px; /* Adjust width to the actual width of bg.png */
   height: 200px; /* Adjust height based on image dimensions */
   background-image: url(/public/images/bg.png);
   background-repeat: no-repeat;
   background-size: contain;
   position: absolute;
   left: 50%; /* Centers horizontally */
   bottom: 30px; /* Moves half of the image height below the table */
   transform: translateX(-50%); /* Centers the image */
   z-index: -1; /* Keeps it behind the table */
   display:none;
}
.transparent-table2{
   padding-bottom:50px;
}
.linknostyle{
   color:inherit;
   border-bottom:1px solid;
}

.table-sc .container2 {
   max-width: 1200px!important;
}

.transparent-table .btn-sq {
   width: 100%;
   text-align: center;
   display: block;
   margin-top: 15px;
   border-radius: 5px;
   padding: 16px;
   background: linear-gradient(97.1367deg, rgb(13, 13, 13) -17.5195%, rgb(79, 79, 79) 273.526%);
}

.transparent-table .amount {
   font-size: 14px;
   font-weight: 300;
}

.transparent-table .amount span {
   font-size: 40px;
   padding-right: 5px;
   color: white;
}

.transparent-table p {
   font-size: 14px;
   margin: 0;
   margin-top: 15px;
   font-weight: 400;
}

.f-row h3 {
   font-size: 22px;
   font-weight: 500;
}

.transparent-table td,
.transparent-table td {
   width: 25%;
}
.accordion-button {
   font-weight: 500;
   font-size: 18px;
}

.faq-sc .h1 {
   font-size: 48px;
   font-weight: 400;
   letter-spacing: -0.96px;
   background: linear-gradient(280deg, rgba(255, 255, 255, 0) -66.5884%, rgb(255, 255, 255) 95.0568%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.pricing-mob-main {
   padding: 32px 32px 6px;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.pricing-table-title-block {
   backdrop-filter: blur(7.5px);
   background-color: #0a0a0a;
   border-radius: 17px;
   display: flex;
   align-items: flex-start;
   flex: 0 0 auto;
   flex-flow: column;
   gap: 8px;
   height: 117px;
   overflow: visible;
   padding: 16px;
   position: relative;
   width: 100%;
   justify-content: center;
}

.pricing-table-title-block:after {
   content: "";
   border-width: 1px;
   border-color: rgba(255, 255, 255, 0.08);
   border-style: solid;
   width: 100%;
   height: 100%;
   position: absolute;
   box-sizing: border-box;
   left: 0;
   top: 0;
   border-radius: inherit;
   pointer-events: none;
}

.pricing-table-title-block .title {
   display: flex;
   align-items: center;
   gap: 16px;
   margin: 0;
   width: 100%;
}
.pricing-table-title-block .title h5 {
   background-image: linear-gradient(1367deg, rgba(255, 255, 255, 0) -90%, rgb(255, 255, 255) 100%);
   font-size: 16px;
   font-weight: 500;
   margin: 0px;
   padding: 0px;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.pricing-table-title-block .title .percentage {
   color: #009dff;
   place-content: center;
   align-items: center;
   background-color: #0d1923;
   border-radius: 20px;
   display: flex;
   flex: 0 0 auto;
   flex-flow: row;
   gap: 0px;
   height: min-content;
   overflow: visible;
   padding: 10px 20px;
   position: relative;
   font-size: 12px;
   font-weight: 500;
}
.pricing-table-title-block .title-para {
   font-size: 16px;
   font-weight: 500;
   color: rgb(132, 138, 159);
   margin: 0;
   text-align: start;
}

.plan-card-main {
   --border-bottom-width: 1.045731782913208px;
   --border-color: rgba(255, 255, 255, 0.1);
   --border-left-width: 1.045731782913208px;
   --border-right-width: 1.045731782913208px;
   --border-style: solid;
   --border-top-width: 1.045731782913208px;
   -webkit-backdrop-filter: blur(7.84298849105835px);
   /* align-content: center;
   align-items: center; */
   backdrop-filter: blur(7.84298849105835px);
   background-color: #0a0a0a;
   border-radius: 17px;
   display: flex;
   flex: none;
   flex-direction: column;
   flex-wrap: nowrap;
   gap: 0px;
   height: min-content;
   /* justify-content: center; */
   overflow: visible;
   padding: 0;
   position: relative;
   width: 100%;
}
.secondmain {
   background-color: #060606;
}
.plan-card-main:after {
   content: "";
   border-width: 1px;
   border-color: rgba(255, 255, 255, 0.08);
   border-style: solid;
   width: 100%;
   height: 100%;
   position: absolute;
   box-sizing: border-box;
   left: 0;
   top: 0;
   border-radius: inherit;
   pointer-events: none;
}

.card-title-main {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   width: 100%;
   gap: 28px;
   padding: 28px;
}

.card-title-main .card-price-main {
   display: flex;
   align-items: flex-end;
   gap: 8px;
}

.card-title-main .card-price-main h4 {
   font-size: 40px;
   font-weight: 500;
   letter-spacing: -1.6px;
   background: linear-gradient(1367deg, rgba(255, 255, 255, 0) -90%, rgb(255, 255, 255) 100%);
   background-clip: text;
   -webkit-text-fill-color: transparent;
   margin: 0px;
   padding: 0px;
   line-height: 48px;
}
.card-title-main .card-price-main span {
   font-size: 16px;
   font-weight: 500;
   letter-spacing: normal;
   color: rgb(157, 157, 157);
   margin: 0px;
   padding: 8px 0px;
   line-height: 20px;
}

.choose-plan-btn {
   align-content: center;
   align-items: center;
   background: linear-gradient(
      97.13668225118039deg,
      #161616 20.169151599212558%,
      rgba(22, 22, 22, 0) 199.9183724265411%
   );
   border-radius: 4px;
   display: flex;
   flex: none;
   flex-direction: row;
   flex-wrap: nowrap;
   gap: 0px;
   border: none;
   height: min-content;
   justify-content: center;
   overflow: visible;
   padding: 16px 24px;
   position: relative;
   width: 100%;
   font-size: 18px;
   font-weight: 600;
   color: rgb(230, 233, 245);
   line-height: 20px;
}

.price-table-row {
   padding: 20px 16px;
   height: 84px;
   display: flex;
   flex-direction: column;
   gap: 4px;
   position: relative;
}
.price-table-row::after {
   content: "";
   border-top: 1px solid rgba(255, 255, 255, 0.1);
   width: 100%;
   height: 100%;
   position: absolute;
   box-sizing: border-box;
   left: 0;
   top: 0;
   border-radius: inherit;
   pointer-events: none;
}

.price-table-row h6 {
   margin: 0px;
   padding: 0px;
   text-align: left;
   font-size: 16px;
   font-weight: 500;
   line-height: 20px;
   color: #fff;
}

.price-table-row p {
   margin: 0px;
   padding: 0px;
   text-align: left;
   font-size: 14px;
   color: rgb(167, 170, 176);
   font-weight: 500;
   line-height: 20px;
}

.price-table-row .check-list p {
   font-size: 16px;
   font-weight: 500;
   line-height: 26px;
   color: #fff;
}

@media (max-width: 575px) {
   .card-title-main {
      padding: 20px;
      gap: 20px;
   }
   .pricing-mob-main {
      padding: 20px 7px 6px;
   }
   .pricing-table-title-block .title {
      justify-content: space-between;
   }
   .card-title-main .card-price-main h4 {
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
   }
   .card-title-main .card-price-main {
      gap: 2px;
   }
   .card-title-main .card-price-main span {
      font-size: 14px;
      padding: 4px 0px;
      line-height: 18px;
   }
   .choose-plan-btn {
      font-size: 16px;
   }
   .price-table-row h6 {
      font-size: 14px;
   }
   .price-table-row p {
      font-size: 14px;
   }
   .price-table-row {
      height: fit-content;
   }
   .price-table-row .check-list p {
      font-size: 14px;
      line-height: 20px;
   }
   .testimonial-sc .top-title {
      margin-bottom: 14px !important;
   }
   .testimonial-sc .h1 {
      font-size: 24px;
   }

   .space-mr-m {
      margin: 40px 0px !important;
   }
   .Pricing-sec .top-title {
      font-size: 12px;
      margin-bottom: 20px !important;
   }
   .faq-sc .h1 {
      font-size: 28px;
      letter-spacing: -0.96px;
   }
}

@media (min-width: 1200px) {
   .pricingdesc {
      margin-bottom:-20px;
   }
   .testimonial-slider {
      width: 830px;
   }
   .testimonial-slider .title {
      font-size: 30px;
      line-height: 40px;
      background: -webkit-linear-gradient(#eee, #333);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
   }
}
@media (max-width: 600px) {
   .transparent-table th,
   .transparent-table td {
      font-size: 14px;
      padding: 6px;
   }
}

@media (max-width: 1199px) {
   .testimonial-slider .slick-list {
      overflow: hidden !important;
   }

   .testimonial-slider {
      width: 100%;
   }

   .transparent-table .amount span,
   .f-row h3 {
      font-size: 20px;
   }

   .transparent-table .btn-sq {
      padding: 10px;
      font-size: 12px;
   }

   .transparent-table {
      padding-left: 0px;
      padding-right: 0px;
   }
}

@media (max-width: 767px) {
   .question-sc .img-sc img {
   width: 46px;
   height: 46px;
   border: 1px solid white;
   margin-bottom:-10px;
}
   .transparent-table {
      overflow: unset !important;
   }

   .transparent-table .table-inner {
      overflow-x: visible !important;
   }

   .transparent-table table {
      width: 600px !important;
   }

   .space-mr-m {
      margin: 60px 0px;
   }

   .transparent-table {
      margin-top: 30px;
   }

   .transparent-table {
      padding-bottom: 50px;
   }

   .testimonial-sc .container-fluid {
      width: 100%;
   }

   /* .testimonial-block {
      transform: unset !important;
   }

   .testimonial-block,
   .question-sc .inner-sc {
      padding: 10px;
   } */

   /* .title {
      font-size: 20px;
   } */

   .testimonial-slider-main .testimonial-block {
      padding: 24px 16px;
      gap: 20px;
   }

   .testimonial-slider-main .testi-title {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.44px;
   }

   .testimonial-slider-main .person-name {
      font-size: 16px;
   }
   .testimonial-slider-main .person-designation {
      font-size: 14px;
   }

   .img-sc img {
      width: 60px;
      height: 60px;
   }

   .transparent-table {
      padding: 0px 0px 60px 0px;
   }

   .transparent-table:after {
      content: none;
   }
}
.off-40 {
   color: rgb(0, 157, 255) !important;
   background-color: rgb(9, 21, 31) !important;
   padding:10px 15px !important;
   border-radius:20px !important;
   font-size:14px !important;
   font-align:center !important;
   align-items: center !important;
   display: flex !important;
   flex: 0 0 auto !important;
   margin-left:20px !important;
   font-weight:400;
}
.tabletitle {
   width: fit-content;
   background: -webkit-linear-gradient(left, #fff, #666);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   font-weight: 500;
   margin-top:7px;
}
.subtitlepricing {
   width: fit-content;
   background: -webkit-linear-gradient(left, #fff, #333);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   margin:auto;
   margin-bottom:20px;
   font-weight:350;
}

@media only screen and (max-width: 600px) {
   .subtitlepricingabove {
      margin-top:30px;
      }
   .accordion-button{
      font-size:14px;
      font-weight:500;
      }
   .question-sc {
      padding-bottom:40px;
   }
}
@media only screen and (min-width: 1000px) {
.specialtitle{
   width:50%;
}}
.pricingheadtitle h1{
   font-weight:400;
}
.swiper-pagination-bullets{
   margin-top:-30px;
}
.p-18{
   font-size:18px;
   font-weight:400;
}
.p-16{
   font-size:16px;
}
.imagesquestions {
   margin-top:15px;
   font-size:18px;
}
.dollarsignp {
   font-size:24px!important;
}
@media only screen and (max-width: 600px) {
   .pricingheadtitle {
      margin-top:-10px;
      }
   .imagesquestions {
      font-size:16px !important;
      }
   .p-18 {
      font-size:14px !important;
      }
   .p-16 {
   font-size:14px !important;
   }
   .mobiledisplaynone {
   display:none;
   }
   .titleminmargintop {
      margin-top:0px;
   }
   .mt-5 {
      margin-top:20px !important;
          margin-left: 5px;
    margin-right: 5px;
   }
}
.testip {
      color: #8e9096;
   }
.faqp {
      color: #8e9096;
   }
@media only screen and (min-width: 1800px) {
.table-sc .container2 {
   max-width: 1350px!important;
}
}
.blogsettings{
   margin-top:80px;
   margin-bottom:140px;
}
.centered {
   position:absolute;
   left:0%;
   right:0%;
   margin:auto;
   width:650px;
   align-items: center;
   text-align: center!important;
   margin-left:auto!important;
   margin-right:auto!important;
}
.selectedblog{
    background-color: rgba(38, 38, 38, 1) !important;
    color: white;
    border-color: #555;}
    .selectedbtn {
        background-color: darkgray;
        color: #555;
        background: #111 !important;
        pointer-events: none;
        position: relative;
        z-index: 1;
        transition: none !important;
     }
     
     .selectedbtn:hover {
        background-color: darkgray !important;
        color: #555 !important;
        background: #111 !important;
        opacity: 1 !important;
        filter: none !important;
        transition: none !important;
     }
     
     .settings-icon {
        position: absolute;
        right: 15px;
        top: 46%;
        transform: translateY(-50%);
        font-size: 14px;
        cursor: pointer !important;
        color: #555;
        transition: color 0.3s ease;
        z-index: 10;
        pointer-events: auto;
     }
     
     .selectedbtn::after {
        content: none !important;
        display: none !important;
        animation: none !important;
        transition: none !important;
        opacity: 0 !important;
        left: -22px !important;
     }
