.fullscreen-div {
    max-width: 1440px;
    margin-left:auto;
    margin-right:auto;
    height:120vh;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
}
.titledivv {
    float:left;  
    margin-top:19vh;
   }
   .urlmagnetformdiv{
    margin-top:30vh;
 }
 .desktopmock{
    margin-top:17vh;
    transition: transform 0.7s ease-out;
 }
 .mobilemock{
    margin-top:17vh;
    transition: transform 0.7s ease-out;
 }
 .started{
    transform: translateY(29vh);
 }
 .steponecompleted{
    display:none;
    position:relative;
 }
 .txtar{
    scale:0.94;
 }
 .authsuccessmaydownload{
   opacity:0;
}
 .stepsevenbtn{
    opacity:0;
    pointer-events:none;
    z-index:0;
    position: absolute;
    left:53%;
    padding: 12px 20px 12px 20px;
    top:57%
}
.authlockbtn{
   margin-top:30px;
   margin-bottom:30px;
   padding: 12px 20px 12px 20px;
}
.needmorehelp{
    opacity:0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left:63%;
    font-size:14px;
    top:58.5%
}
.thegif{
    opacity:0;
    visibility: hidden;
    position:absolute;
   top:67%;
   left:53%;
   right:50%;
   background-color:#333;
   width:520px;
   height:300px;
   border-radius: 5px;
   border: 1px solid #333

 }
 .thesecondstep {
    opacity:0;
    visibility:hidden;
    z-index:0;
    position: absolute;
    top:20%;
    left:17%;
    width:1050px;
 }
 .special-instructions-menunew {
    display:inline-flex;
    width:auto;
    height:auto;
    position:absolute;
    top:0%;
    z-index: 500;
   }
   .menubtnnew{
    padding-left:8px;
    padding-right:8px;
    font-weight:500;
   }
   .steptwobtn {
    margin-top:15px;
    margin-left:160px;
    padding: 12px 20px 12px 20px;
 }
.showsecond {
    display:block;
 }
 .steptwocompleted{
    display:none;
 }
 .mobilemockupduringstepthree{
    transform: translateX(-57vh);
   }
.stepseven{
    opacity:0;
    visibility:hidden;
    z-index:0;
    pointer-events:none;
    position:absolute;
   top:37%;
   left:53%;
   right:50%;

   width:300px;
   height:250px;
   border-radius: 5px;
   }
   .thethirdstep {
    opacity:0;
    visibility:hidden;
    z-index:0;
   }

   .viewthird{
    display:block;
 }
 .thirdsteptitle{
    position: absolute;
    left:80%;
    right:0;
    top:24%
   }
   .stepthreebutton{
    position: absolute;
    left:60%;
    padding: 12px 20px 12px 20px;
    top:45%
   }
   .extractforms{
    position: absolute;
    left:73%;
    top:37%
   }

   .mobilemockupduringstepfour{
    transform: translateX(-73vh);
    }
.stepfournodisplay{
   display:none;
  }
  .thefourthstep{
    opacity:0;
    visibility:hidden;
    pointer-events:auto;
   }
  .showfour{
    display:block;
   }
.stepfivebutton {
   margin-top:130px;
   opacity:0;
   visibility:hidden;
}
.stepsixhalfbtn{
   margin-top:250px;
   opacity:0;
   visibility:hidden;
}
   .fourthsteptitle{
   position:absolute;
   top:12%;
   left:53%;
   right:50%;
  }
  .fourthstepdesc{
   position:absolute;
   top:37%;
   left:53%;
   right:50%;
   width:500px;
  }
  .threestepsdiv{
   background-color:#333;
   width:300px;
   height:60px;
   top:57%;
   left:53%;
   right:50%;
   position:absolute;
  }
.fourthsteptitle{
   position:absolute;
   top:26%;
   left:53%;
   right:50%;
  }
  .authdiv{
   position:absolute;
   top:37%;
   left:53%;
   right:50%;
   width:450px;
   height:380px;
   border-radius: 5px;

  }
  .characterlimits{
   visibility: hidden;
   opacity:0;
   position:absolute;
   left:38%;
   margin-top:20vh;
   color:#333;
   width:310px;
   scale:0.8;
 }
  .threestepsdiv{
   background-color:#333;
   width:300px;
   height:60px;
   top:97%;
   left:53%;
   right:50%;
   position:absolute;
  }
  .whatsnextdiv{
   top:37%;
   left:53%;
   right:50%;
   position:absolute;
   opacity:0;
   visibility: hidden;
  }
.thefifthstep {
   visibility: hidden;
    opacity:0;
   }

.hidefour, .hideelement{
    display:none;
   }
   .showfive, .showelement{
    display:block;
   }

.columnsz {
    display:flex;
    columns:2;
    gap:20px;
    position:absolute;
   top:44%;
   left:53%;
   right:50%;
   width:400px;
   height:100px;
   border-radius: 5px;
   border: 1px solid #333
}
.columnsz2{
   opacity:0;
   visibility: hidden;
   display:flex;
   columns:2;
   gap:20px;
   position:absolute;
  top:58%;
  left:53%;
  right:50%;
  width:400px;
  height:100px;
  border-radius: 5px;
  border: 1px solid #333
}
.columnsz3 {
   opacity:0;
   visibility: hidden;
   display:flex;
   columns:2;
   gap:20px;
   position:absolute;
  top:72%;
  left:53%;
  right:50%;
  width:400px;
  height:100px;
  border-radius: 5px;
  border: 1px solid #333
}
.columntwoo{
    float:left;
    width:75%;
    margin-top:15px;
    margin-left:-40px;
}
.columnonee{
    float:right;
    width:25%;
    margin-top:12px;
    margin-left:5px;
    margin-right:5px;
}
.stepfourbutton{
    pointer-events: none;
    position: absolute;
    left:53%;
    padding: 12px 20px 12px 20px;
    top:59%
}
.nodisplayimportant{
    display:none!important;
}
.listoee{
    scale:0.9;
}
.fade-in {
    opacity: 1;
    transition: opacity 0.9s ease-in-out;
    z-index:100!important;
    pointer-events:auto;
    visibility: visible;
 }
 
 .fade-out {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    z-index:0;
    pointer-events: none;
    visibility: hidden;
 }
 .btn {
    z-index:50;
 }
 .thisoneriht{
    pointer-events:auto;
 }
 .specialfade{
    opacity: 1;
    transition: opacity 0.9s ease-in-out;
    pointer-events:auto;
    z-index:101;
 }
 .newestplus{
    pointer-events:auto;
 }
 .newestplus2{
    pointer-events:auto;
 }
 .newestminus{
    pointer-events:auto;
 }
 .newestminus2{
    pointer-events:auto;
 }
 .controlinputc{
    display:flex;
    position:absolute;
    right:5%;
    top:3%;
    pointer-events: auto;
  }
  .controlinputc2{
    display:flex;
    position:absolute;
    right:8%;
    top:55%;
    pointer-events: auto;
  }
  .txtnewi {
    position:relative;
    float:right;
    z-index:100;
 }
 .tooltip-container1{
    position:absolute!important;
    right:10px;
    top:35px;
 }
 .tooltip-text1{
    font-size:14px!important;
    color:#a7aab0;
    width:270px;
    text-align:left;
    height:110px;

    top:100%;
 }
 .smallimgg{
    width:70px;
    height:70px;
 }
 .secondpma{
    margin-top:-10px;
 }
 .nameauthlock{
   width:100px!important;
   min-width:100px!important;
   float:left;
}
.emailauthlock{
   width:250px!important;
   min-width:250px!important;
   float:right;
   margin-top:0px!important;
   margin-bottom:0.5rem;
}
.glowy{
   position:absolute;
   top:70%!important;
   right:50px!important;
   transition: top 0.5s ease-in-out;
}
.glowfirst{
   position:absolute;
   top:100%!important;
   right:50px!important;
   transition: top 0.5s ease-in-out;
}

.ordiv{
   margin-left:10px;
   margin-right:0;
   min-width:355px;
}
.ordiv:after{
   width: calc(100% - 40px);
}

.ordiv:before{
   display:none;
}
.authlocklogin{
   color:#333!important;
   margin-top:-5px;
}
.authlockloginclick{
   color:#555!important;
   cursor: pointer;
}
.authlockloginclick:hover{
   color:#fff!important;
}
.authwallclassmain{
z-index:101;
}
@media screen and (min-width: 1600px) {
   .fullscreen-div {
      width: 1440px;
      height: 125vh;
      margin-left:auto;
      margin-right:auto;
   }
}

/* Scale for 1800px screens */
@media screen and (min-width: 1800px) {
   .fullscreen-div {
      width: 1440px;
      height: 125vh;
      margin-right:auto;
   }
}

.loading-animation {
   /* 2s total:
      - 0%→25%: fades to 0.7 (0.5s)
      - 25%→50%: fades back to 1 (0.5s)
      - 50%→100%: holds at 1 (1s delay) */
   animation: fadeLoading 2s ease-in-out infinite;
 }
 
 @keyframes fadeLoading {
   0% {
     opacity: 1;
   }
   25% {
     opacity: 0.5;
   }
   50% {
     opacity: 1;
   }
   75%{
      opacity: 0.5;
    }
   100% {
     opacity: 1;
   }
 }
 .extract-loading-animation {
   /* 2s total:
      - 0%→25%: fades to 0.7 (0.5s)
      - 25%→50%: fades back to 1 (0.5s)
      - 50%→100%: holds at 1 (1s delay) */
   animation: fadeLoading 2s ease-in-out infinite;
 }
 
 @keyframes fadeLoading {
   0% {
     opacity: 1;
   }
   25% {
     opacity: 0.6;
   }
   50% {
     opacity: 1;
   }
   75%{
      opacity: 0.6;
    }
   100% {
     opacity: 1;
   }
 }
 .extractcost2{
   position:absolute;
   top:60%;
   right:11%;
   z-index:10001;
 }
 .extractcost3{
   position:absolute;
   margin-top:9vh;
   width:300px;
 }
 .linknostyle{
   cursor:pointer;
 }
 .mobilealternativefirstdiv, .mobilealternativeseconddiv{visibility: hidden;
 }

.threestepsect {
   columns:3;
   gap:40px;
   margin-left:0vh;
   margin-right:0vh;
   position:flex;
   margin-bottom:20vh;
   max-height:auto;
   margin-top:-70px;
}
.keepscrollingdiv {
   
   max-width:1440px!important;
   scale:0.8;
   margin-left:auto;
   margin-right:auto;
   margin-top:-64vh;
   
}
.sectiontitlehome{
}
.twocolumns{
   columns:2;
   gap:100px;
   margin-left:0vh;
   margin-right:0vh;
   margin-bottom:20vh;
   max-height:auto;
   margin-top:-70px;
}
.fourcolumns{
   columns:4;
   margin-left:0vh;
   margin-right:0vh;
   margin-bottom:20vh;
   max-height:auto;
   margin-top:30px;
}
.fourcolumnstwo {
   columns:4;
   margin-left:0vh;
   margin-right:0vh;
   margin-bottom:0vh;
   margin-top:-120px;
   max-height:auto;

}
.fourcolumnstwothird{
   margin-top:50px;
   margin-bottom:20vh;
   
}
.steponesect {
   border:0.5px solid #333;
   border-radius:5px;
   padding:50px;
   background-color: #0a0a0a;
}
.steptwosect {
   border:0.5px solid #333;
   border-radius:5px;
   padding:50px;
   background-color: #0a0a0a;
}
.stepthreesect {
   border:0.5px solid #333;
   border-radius:5px;
   padding:50px;
   
}
.imagesteps{
   padding-bottom:10px;
   width:100px;
   margin-left:-5px;
   height:auto;
}
.imagestepsother{
   padding-bottom:10px;
   width:55px;
   margin-left:-5px;
   height:auto;
}
.specialgpt{
   opacity:0.8;
}
.titleinsteps{
   padding-top:5px;
   padding-bottom:20px;
   color:white;
}
.sectt{
   padding:30px;
   background-color: #0a0a0a;
}
.agiffor{
   width:600px;
   opacity:0.9;
}
.threecolumns{
   columns:3;
   gap:50px;
   margin-top:30px;
   margin-bottom:20vh;
  }
  .twocolumnsspecial{
   columns:1;
   margin-top:40px;
}
.betasection{
   margin-bottom:-280px;
}
.betasectionps{
   margin-bottom:-120px;
}
.specialfirstcolumn{
   position:block;
   width:40%;
   float:left;
   border-right:0px;
}

.specialsecondcolumn{
   position:block;
   width:60%;
   float:right;
   border-left:0px;
}
.firststardiv{
   margin-top:0px;
   padding-bottom:30px!important;
}
.starsfirst{
   max-width:130px;
}
.starssecond{
   margin-top:-10px;
}
.firststartext{
   padding-top:4px;
   margin-left:7px;
  font-size:14px;
  color:#666;
  margin-bottom:20px;
}
.productunt{
   max-width:200px;
   
}
.productuntdiv{

   text-align: right;
   margin-right:-50px;
   margin-top:-30px;
}
.headsfloating {
   max-width: 1100px;
   padding: 30px;
   margin: auto;
   text-align: center;
   margin-top: -200px;
   margin-bottom:0px;
 }
 
 /* The marquee container hides overflow */
 .marquee {
   overflow: hidden;
   position: relative;
   margin-top: 4vh;
 }
 
 /* Flex container for images */
 .marquee-content {
   display: flex;
   /* Adjust the duration to your liking; here 10s means the 1000px shift happens in 10 seconds */
   animation: marquee 15s linear infinite;
 }
 
 /* For consistent image size and spacing */
 .identity {
   max-width: 40px;
   margin: 0 30px;
   flex-shrink: 0;
   opacity:0.8;
   scale:0.8
 }
 .ascomponentscroll{
   margin-top:-19em!important;
 }
 
 /* Animation for leftward scroll (freelancers) */
 @keyframes marquee {
   0% {
     transform: translateX(0);
   }
   100% {
     /* Shift left by 1000px (i.e. the width of 10 images) */
     transform: translateX(-1000px);
   }
 }
 
 /* Reverse animation for agencies */
 .agencies .marquee-content {
   animation: marquee-reverse 15s linear infinite;
 }
 
 @keyframes marquee-reverse {
   0% {
     transform: translateX(-1000px);
   }
   100% {
     transform: translateX(0);
   }
 }
.iconsnexttotitle{
   float:right;
   margin-top:-65px;
}
.problemsolutionicon{
  max-width:40px; 
}
.largerbecauseless{
   max-width:55px; 
 }
 .accoladeimg{
   margin-top:-36vh;
   max-width:1060px; 
   opacity:0.4;
 }
 .accoladediv{
   
   margin-top:-14vh;
   margin-left:430px;
   margin-bottom:30vh;
 }
 .ppcreplaces{
   max-width:150px;
   float:left;
   
 }
 .casualsidetext{
   float:left;
   margin-top:5vh;
   margin-left:30px;
   color:#666;
 }
.subimages{
   display:inline-flex;
   margin-left:50px!important;
   margin-top:10px;
   margin-left:-10px;
 }
 .productlogo{
   width:200px;
   margin-left:40px;
 }
 .twosubimages{
   width:150px;
   float:right;
   margin-right:95px;
 }
 .screenshotimg{
   margin-top:15px;
   cursor:pointer;
 }
 .topstuffhome{
   margin-top:30px;
  }
  .hdlzo{
   margin-top:70px!important;
   margin-bottom:1.8em!important;
  }
  .secondtopstuff{
   width:60%;
   margin-left:auto;
   margin-right:auto;
   margin-top:-140px;
   margin-bottom:200px;
  }
 .threescreens{
   max-width:112px;
   margin-left:5px;
   margin-right:5px;
 }
 .thumbnailbtns{
   text-align:center;
   align-items: center;
   margin-left:50px;
   
   margin-top:30px;
   margin-bottom:10px;
   display:inline-flex;
   gap:20px;
 }
 .pricingdiscount{
   font-size:56px;
   margin-left:25px;
   color:white!important;
 }
 .usualpricing {
   font-size: 38px;
   text-decoration: line-through;
   text-decoration-color: white;
   color: inherit; /* Ensures text color remains as intended */
}
.permonth{
   color:#666;
 }
 .special-btn{
   float:left;
   padding:30px;
   padding-top:15px;
   padding-bottom:15px;
   font-size:24px;
   margin-bottom:10px;
 }
 .offer-text{
   margin-top:0px;
   margin-bottom:30px;
 }
.guarantee-text{
   padding-top:18px;
   padding-left:272px;
   color:#666;
   margin-bottom:40px;
 }
 .titleinoffer{
   margin-top:17px;
 }
 .othertext{
  padding-top:10px;
  padding-bottom:15Px;
   color:#666;
 }
 .nogniet{
   display:none;
  }
  .comparetablediv {
   /* Ensures the container does not exceed screen width */
   max-width: 100%;
   /* Allows horizontal scrolling when content is wider than screen */
   overflow-x: auto;
   margin-top: 30px;
   margin-bottom: 20vh;
 }
 
 /* Optional: force the table to maintain a minimum width */
 .comparetable {
   width: 100%;
   min-width: 800px; /* or whatever width you prefer */
   
   background-color: #0a0a0a;

 }
 
 /* Existing styling */
 .comparetable{
   color: #666;
   text-align: center;
   border-radius: 15px!important;
 }
 .comparetablerow {
   padding: 20px;
 }
 .tablecd {
   padding: 20px;
   border-bottom: 0.5px solid #333;
   border-left: 0.5px solid #333;
 }
 .featurecd {
   width: 30%;
   font-weight: 500;
   font-size: 22px;
   color: #a7aab0;
   text-align: left;
   
   padding-top: 25px;
   padding-bottom: 25px;
 }
 .getstartedbtn{
   margin-left:auto;
   font-size:24px!important;
   margin-right:auto;
   padding-top:25px!important;
   padding-bottom:25px!important;
   padding-left:50px!important;
   margin-top:0px;
   padding-right:50px!important;
 }

 .lightbox-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.85);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 9999;
 }
 
 .lightbox-overlay img {
   max-width: 90%;
   max-height: 90%;
 }
 
 .arrow {
   background: none;
   border: none;
   color: #fff;
   font-size: 3rem;
   cursor: pointer;
   padding: 1rem;
 }
 
 .arrow.left {
   position: absolute;
   left: 2%;
 }
 
 .arrow.right {
   position: absolute;
   right: 2%;
 }
 .lightbox-overlay {
   animation: fadeIn 0.3s;
 }
 
 @keyframes fadeIn {
   from { opacity: 0; }
   to { opacity: 1; }
 }
 .cursor-trail {
   position: absolute;
   width: 6px;          /* smaller size */
   height: 6px;
   background: #a7aab0;
   border-radius: 50%;
   pointer-events: none;
   filter: blur(2px);   /* subtle blur for a glow-like effect */
   opacity: 0.6;
   transform: translate(-50%, -50%);
   animation: cursorGlowFade 0.5s forwards;
   z-index: 1;
 }
 .whitetxt{
   color:white;
 }
 
 @keyframes cursorGlowFade {
   0% {
     transform: translate(-50%, -50%) scale(1);
     opacity: 1;
   }
   100% {
     transform: translate(-50%, -50%) scale(3);
     opacity: 0;
   }
 }

 .cursorbehind {
   position: relative!important;
   z-index: 3!important;
}
.iconintable{
width:35px;
float:left;
margin-right:15px;
}
.sectnopad{
   padding:10px!important;
   cursor:pointer;
   }
   .clickable{
     
      cursor:pointer;
      }

.minilogos{
   width:30px;
   margin-bottom:12px;
   margin-top:5px;
}

.secondcheck{
   margin-left:20px;
}
.scrollmetable{
   display:none;
}
.screen-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.3); /* Dark translucent background */
   opacity: 0; /* Hidden by default */
   transition: opacity 0.3s ease;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index:2;
   margin-top:-20px;
   cursor:pointer;
 }
 
 .innerborderscreen:hover .screen-overlay {
   opacity: 1; /* Fade in overlay on hover */
 }
 
 .lock-icon {
   fill: #808080; /* Grey color */
   width: 50px;
   height: 50px;
 }
 .leftopcorner{
   position: absolute;
   top:3%;
   left:3%;
   font-size:14px;
   color:#666!important;
}
.testbtnim{
   position: absolute;
   margin-left:10em;
   margin-top:3.3em;
}
.testbtnecht{
   padding:15px 20px;
   font-size:18px;
}
.excel-overlay{
   opacity:0.5;
   width:100%;
   height:auto;
   border-top: 3px solid #0a0a0a;
   border-left:3px solid #0a0a0a;
   border-right:3px solid #0a0a0a;
   z-index:80;
   border-radius:10px;
   margin-bottom:-20px;
   
}

@media screen and (max-width: 764px) {
   .fullscreen-div {display:none;
   }
   .mobilealternativefirstdiv{
      text-align:center;
      margin-top:10vh;
      margin-bottom:15vh;
      visibility:visible
   }
   .mobilealternativeseconddiv{
      margin-bottom:15vh;
      visibility:visible;
   }
   .threestepsect {
      columns:1;
      gap:0px;
      margin-left:0vh;
      margin-right:0vh;
      position:flex;
      margin-bottom:8vh;
      max-height:auto;
      margin-top:0vh;
   }
   .sectt {
      padding:20px!important;
      
   }
   .imagesteps{
      padding-bottom:10px;
      width:100px;
      margin-left:-10px;
      height:auto;
   }
   .titleinsteps{
      padding-top:5px;
      padding-bottom:10px;
      color:white;
   }

   .generatepanother {
      padding-bottom:3vh;
   }
   .thisoneiscool {
      padding-top:8vh;
   }
   .firststardiv{
      margin-top:-50px;
   }
   .secondtopstuff{
      margin-top:-50px;
      width:90%;
   }
   .productunt{
      max-width:120px;
      
   }
   .productuntdiv{
   
      text-align: right;
      margin-right:-10px;
      margin-top:-20px;
   }
   .identity{
   max-width:30px;}
   .twocolumns{
      columns:1;
   margin-bottom:10vh;}
      .keepscrollingdiv{
         margin-top:-83vh;
      margin-left:-10px;margin-right:-10px;}

   .iconsnexttotitle{
   float:right;
   margin-top:-38px;
}
.problemsolutionicon{
   max-width:30px; 
 }
 .steponesect{
   padding:20px!important;
   margin-bottom:5vh;
   
 }
 .agiffor{
   width:100%!important;
   opacity:0.9;
}
.fourcolumns{
   columns:2;
   margin-bottom:10vh;
}
.imagesteps{
   width:50px;
}
.nomobile{
   display:none;
}
.steptwosect{
   padding:20px;
   margin-top:0px;
}
.steptwosects{
   
   margin-top:-30px;
}
.stepfoursect{
   margin-top:10px;
}
.fourcolumnstwo{
   columns:2;
   margin-top:-8vh;
}
.fourcolumnstwothird{
   margin-bottom:10vh;
   margin-top:2vh;
}
.featurecd{
   font-size:18px;
}
.specialfirstcolumn{
   position:block;
   width:100%;
   float:none;
   border-right:0px;
}

.specialsecondcolumn{
   position:block;
   width:100%;
   border-left:0.5px solid #333;
   margin-top:-70px;
   border-top:0px;
   margin-bottom:0px!important;
}

.twosubimages{
   display:inline-flex;
   float:none;
   margin-left:40px;
   gap:10px;
   margin-top:10px;

 }
 .firsttwoscreens{
   width:130px;
 }
 .specialfirstcolumn{
   border-right:0.5px solid #333;
   border-bottom:0px;
 }
 .special-btn{
   float:none;
   padding:30px;
   padding-top:15px;
   padding-bottom:15px;
   font-size:24px;
   margin-bottom:10px;
 }
 .getstartedbtn{
   margin-left:auto;
   font-size:24px!important;
   margin-right:auto;
   padding-top:25px!important;
   padding-bottom:25px!important;
   padding-left:50px!important;
   margin-top:0px;
   padding-right:50px!important;
 }
 .thumbnailbtns{
   margin-left:0px;
   margin-right:-10px;
   margin-top:30px;
   margin-bottom:10px;
   gap:10px;
 }
 .starssecond{
   margin-top:0px;
}
.othertext{
   padding-bottom:0px;
}
.pricingdiscount{
   font-size:46px;
   margin-left:10px;
   color:white!important;
 }
 .usualpricing {
   font-size: 22px;
   text-decoration: line-through;
   text-decoration-color: white;
   color: inherit; /* Ensures text color remains as intended */
}
.guarantee-text{
   padding-top:18px;
   padding-left:0px;
   color:#666;
   margin-bottom:10px;
   float:none;
 }
 .comparetable{
 margin-bottom:30px;
}
.comparetablediv{
   
   margin-bottom:10vh;
  }
  .betasection{
   margin-bottom:-59vh;
  }
  .betasectionps{
   margin-bottom:-26vh;
  }
  .subimages{
   margin-left:0px!important;
  }
  .productlogo{
   margin-left:80px;
  }
  .iconintable{
   padding-top:5px;
  }
  .sectnopad{
   padding:0px!important;
   }
   .secondcheck{
      margin-left:10px;
      }
      .scrollmetable{
         display:block;
         font-size:10px;
         margin-left:75px;
         }
         .threecolumns{
            columns:1;
                   
            padding-top:40px;     
         }
}
