body {
   margin: 0;
   overflow-x: hidden;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
   overflow-x:  hidden;
}


.backdrop-main {
   display: flex;
   align-items: center;
   gap: 20px;
   opacity: 0.5;
   max-width: 100vw;
}

.backdrop-elm {
   height: 400px;
   max-width: 600px;
   width: 100vw;
   filter: blur(100px);
   animation: combined 13s linear infinite, blob 8s ease-in-out infinite;
}

/* Combined rotation and horizontal movement */
@keyframes combined {
   0% {
      transform: rotate(0deg) translateX(0);
   }
   50% {
      transform: rotate(180deg) translateX(100px);
   }
   100% {
      transform: rotate(360deg) translateX(0);
   }
}

/* Random blob shape animation */
@keyframes blob {
   0%,
   100% {
      border-radius: 40% 60% 70% 30% / 50% 50% 60% 40%;
   }
   25% {
      border-radius: 30% 70% 50% 50% / 40% 60% 70% 30%;
   }
   50% {
      border-radius: 50% 50% 30% 70% / 60% 40% 50% 50%;
   }
   75% {
      border-radius: 60% 40% 40% 60% / 50% 70% 30% 70%;
   }
}

.backdrop-elm-2 {
   height: 400px;
   max-width: 600px;
   width: 100vw;
   filter: blur(100px);
   opacity: 0.7;
   animation: combined2 13s linear infinite, blob2 8s ease-in-out infinite;
}

@keyframes combined2 {
   0% {
      transform: translateX(-500px);
   }
   50% {
      transform: translateX(-100px);
   }
   100% {
      transform: translateX(-500px);
   }
}

/* Random blob shape animation */
@keyframes blob2 {
   0%,
   100% {
      border-radius: 40% 60% 70% 30% / 50% 50% 60% 40%;
   }
   25% {
      border-radius: 30% 70% 50% 50% / 40% 60% 70% 30%;
   }
   50% {
      border-radius: 50% 50% 30% 70% / 60% 40% 50% 50%;
   }
   75% {
      border-radius: 60% 40% 40% 60% / 50% 70% 30% 70%;
   }
}

.banner-home {
   position: relative;
}

.backdrop-main {
   position: absolute;
   pointer-events: none;
   z-index: -1;
   bottom: -100px;
   right: 50px;
}

.container {
   max-width: 1250px !important;
   width: 100% !important;
}

.backdrop-glow {
   height: 100px;
   max-width: 300px;
   width: 100%;
   position: absolute;
   top: 100px;
   left: 0;
   background-color: #ffffff25;
   filter: blur(40px);
   opacity: 0.7;
   z-index: -1;
   pointer-events: none;
}
h1 {
   font-weight: 400;
}
.backdrop-elm-blue {
   background-color: rgba(0, 0, 255, 0.3); /* Blue for Broad campaign */
 }
 
 .backdrop-elm-red {
   background-color: rgba(255, 0, 0, 0.30); /* Red for Brand campaign */
 }
 
 .backdrop-elm-green {
   background-color: rgba(0, 255, 0, 0.25); /* Green for Page-specific */
 }
 
 .backdrop-elm-purple {
   background-color: rgba(128, 0, 128, 0.35); /* Purple for Language-specific */
 }
 
 .backdrop-elm-white {
   background-color: rgba(255, 255, 255, 0.25); /* Default white glow */
 }
 .backdrop-elm,
.backdrop-elm-2 {
  transition: background-color 0.5s ease-in-out; /* Smooth color transition */
}
