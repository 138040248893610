.footer {
   padding: 36px 0 20px;
}

.footer .logo-desc {
   font-size: 16px;
   font-weight: 400;
}

.footer-nav a {
   font-size: 16px;
}

.copy-right-sc p {
   font-size: 16px;
   color: #fcfcfc;
   opacity: 0.5;
}
.toolforfooter {

   display:none;

   float:right;
   width:300px;
   margin-top:35px;
}
.footertoolbtn {
   padding-top:5px;
   width:300px;
}
.footertoolbtnecht {
   width:100%;
   height: 40px;
    border-radius: 10px;
}
.triangle {
   display:none;
   position: absolute;
   text-align: right;
   right: 6%;
}

.triangle-container {
   position: relative;
   width: 200px;
   height: 200px;
   margin: 0 auto;
}

/* Position the images */
.top-image {
   position: absolute;
   left: 36%; 
   width: 50px;
   height: 50px;
   border-radius: 50%;
   transition: transform 0.3s ease;
   z-index:2;
}

.bottom-left-image {
   position: absolute;
   bottom: 10px;
   left: 10px;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   transition: transform 0.3s ease;
   z-index:2;
}

.bottom-right-image {
   position: absolute;
   bottom: 10px;
   right: 10px;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   transition: transform 0.3s ease;
   z-index:2;
}

/* Hover effect for images */
.top-image:hover,
.bottom-left-image:hover,
.bottom-right-image:hover {
   transform: scale(1.3);
}

/* Lines connecting the images */
.line {
   position: absolute;
   background-color: white;
   width: 1px;
   transform-origin: top;
   z-index:1;
}

/* Line from top to bottom left */
.line1 {
   top: 35px;
   left: calc(50% - 1px);
   height: 60px;
   transform: translateX(-50%) rotate(335deg);
   transform-origin: top center;
}

/* Line from top to bottom left */
.line2 {
   top: 35px;
   left: calc(50% - 1px);
   height: 60px;
   transform: rotate(25deg);
}

/* Line connecting bottom left to bottom right */
.line3 {
   bottom: 35px;
   left: 60px;
   width: 60px;
   height: 1px;
   background-color: white;
}
@media only screen and (max-width: 600px) {
   .footer .logo-desc {
      font-size:14px;
   }
   .footer-nav a {
      font-size:14px;
   }
   .copy-right-sc p {
      font-size:14px;
   }
}
