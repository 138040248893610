.centeredmain{
    max-width:1150px;
    max-height:900px;
    margin:auto;
    margin-top:10vh;
}
.titledashboardsec{
    font-size:16px;
    color:white;
    font-weight:500;
}
.smallertitledashboard{
    font-size:12px;
    color:light-grey;
    font-weight:500;
    display:none;
}
.threesectionsdashboard{
    columns:3;
    gap:40px;
}
.menuprompts{
    margin-top:-3px;
    width:407px;
    border-bottom:3px solid #2f2f2f;
    
   }
.promptinmenu{
    cursor:pointer;
 font-size:11px;
   padding-left:7.8px;
   padding-top:4px;
   padding-bottom:5px;
   padding-right:7.8px;
   background-color: #2f2f2f;
   border-top-left-radius: 5px;
   border-top-right-radius:5px;
   
}
.twocolumnsdashboard{
    columns:2;
    gap:30px;
   }
.selectedprompt{
    font-size:12px;
    padding-left:9px;
    padding-right:9px;
    background-color:#1f1f1f;
    
    border-bottom:3px solid #1f1f1f;
   }
.dashboardbox{
    
}
.dashboardlargebox{
    margin-top:30px;
    height:780px;
    width:420px;
}
.mockupsectionn{
    height:700px;
}
.dashboardboxinner{
    border-top:2px solid black;
    border-left:1px solid black;
    border-right:1px solid black;
    background-color: #1f1f1f;
    border-radius:7px;
    min-height:200px;
    background:#1f1f1f;
}
.campaignsettingsbox{
    min-height:180px;
   }
.dashboardboxinnersmall{
    min-height:50px;
    }
.dashboardboxinnerlarge{
    min-height:570px;
    }
.dashboardboxouter{
    margin-top:16px;
    padding:6px;
    border-radius: 10px;
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2), 0 4px 15px rgba(0, 0, 0, 0.5);
    background: linear-gradient(to bottom, rgba(50, 50, 50, 1), rgba(0, 0, 0, 1));

}
.creditcostsdashboard{
    font-size:14px;
    color:#444;
    display:none;
    }
    .creditcostsdashboardfirst{
        float:left;
        margin-top:20px;
        position:relative;
        }
        .creditcostsdashboardsecond{
            float:right;
            display:none;
            }
.downloadbuttondiv{
    margin-top:-40px;
    max-width:1150px;
    margin-left:auto;
    margin-right:auto;
    }
.coolbtnbecuasecool{
    padding-left:20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom:10px;
    margin-left:40px;
    margin-right:40px;
    }
.mobilemockdashboard{
scale:0.9;
}
.clientdata{
    }
    .imginbtn{
        max-width:30px;
        margin-left:5px;
        }
.presetdisplayinput{
    float:right;
    font-size:14px!important;
    color:#444;
    text-align:right;
    background-color:transparent;
    margin-top:2px;
    margin-right:8px;
    }
.presetdisplay{
    float:right;
    font-size:14px;
    color:#444;
    margin-top:4px;
    margin-right:10px;
    }
    .dropdown-content {
        display:grid;
        margin-left:250px;
        columns:1;
        position:absolute;
        background-color: #333;
        align-items: right;
        font-size:12px;
        width:100px;
        height:70px;
        overflow-y: auto;
        
    }
    .dropdown-contentprompts{
        
        margin-left:313px;
    }
    
    .dropdownitemm {
        display:block;
        padding-left:10px;
        padding-top:3px;
        padding-bottom:3px;
        height:22px;
        
        white-space:wrap;
        overflow:hidden;
        text-overflow: ellipsis;
    }
    
    .dropdown-content a:hover {
        background-color: #444;
    }
    .clientdatainput{
        height:200px;
        background-color:#1f1f1f;
        border:1px solid black;
        width:100%;
        vertical-align: top;
        align-items:normal;
        word-break:break-word;
        white-space: pre-wrap;
        text-align:left;
        resize:none;
        scrollbar-width: thin;
        scrollbar-color: #444 #f1f1f1;
        padding:10px;
        

    }
    .clientdatainput::-webkit-scrollbar {
        width: 2px; /* Scrollbar width */
    }
    
    .urldatainput{
        height:50px;
        width:90%;
        margin-left:-30px;
        background-color:#1f1f1f;
        border:0px solid black!important;
        padding-left:10px;
    }
    .enterbtnarrowurl{
        float:right;
        margin-top:-47px;
        margin-right:-4px;
        padding:10px;
        cursor:pointer;
        color:#333;
        transition: color 0.3s ease-in-out;
        }
        .enterbtnarrowurl:hover{
           color:#666;
           transition: color 0.3s ease-in-out;
            }
 .urldatainput:focus{
    border:0px solid black!important;
    
    }
    .promptdatainput {
        height:570px;
        background-color:#1f1f1f;
        border:1px solid black;
        border-top:transparent!important;
        width:100%;
        vertical-align: top;
        align-items:normal;
        word-break:break-word;
        white-space: pre-wrap;
        text-align:left;
        resize:none;
        scrollbar-width: thin;
        scrollbar-color: #444 #f1f1f1;
        padding:10px;
    }
    .promptdatainput:focus {
        border-top:transparent!important;
    }
    .dashboardlanguage{
        width:130px;
    }
    .dashboardcialdini{
        width:170px;
    }
    .dashboardlcp{
        display:inline-flex;
        margin-left:10px;
        gap:8px;
        margin-top:35px;
        
    }
    .dashboardamount{
        font-size:14px;
        background-color:#222;
        background: rgba(38, 38, 38, 0.7) !important;
        padding:10px;
        border-radius:7px;
        width:175px;
        margin-top:10px;
        margin-left:60px;
    }
    .dashboardamountk{
        
        width:224px;
    }
   .dashboardamountinput{
        background:transparent;
        background-color: transparent;
        border-color:transparent!important;
        border:none;
        margin-left:5px;
        width:15px;
    }
    .minusbtndashboard{
        background-color: transparent;
        border:none;
        padding:2px;
        margin:0;
        line-height: 10px;
        margin-top:5px;
        margin-left:10px;
        
    }
    .plusbtndashboard{
        background-color: transparent;
        border:none;
        padding:2px;
        margin:0;
        line-height: 10px;
    }
    .dropeddownl{
        width:130px;
        background-color:#222;
        background: rgba(38, 38, 38, 1) !important;
        
    }

    .dropeddownc{
        width:170px;
        background-color:#222;
        background: rgba(38, 38, 38, 1) !important;
    }
    .loading-animation {
        /* 2s total:
           - 0%→25%: fades to 0.7 (0.5s)
           - 25%→50%: fades back to 1 (0.5s)
           - 50%→100%: holds at 1 (1s delay) */
        animation: fadeLoading 2s ease-in-out infinite;
      }

.leftopcorner{
   position: absolute;
   top:3%;
   left:3%;
   font-size:14px;
   color:#666!important;
}
.rightopcorner{
    position: absolute;
    top:3%;
    right:3%;
    font-size:14px;
    color:#666!important;
 }
.rightmenutop {
    margin: 0px 15px;
    color:#444;
 }
 .rightmenutop:hover {
    text-decoration: underline;
 }
 .bottomleftcorner{
    position: absolute;
    bottom:3%;
    left:1.5%;
    font-size:14px;
    color:#666!important;
 }
 .logotopleft{
    margin-left:-23px;
    margin-top:-13px;
    opacity:0.3;
    transition: opacity 1s ease-in-out;
 }
 .logotopleft:hover{
    opacity:1;
    transition: opacity 1s ease-in-out;
 }
 .backmobilee{
    padding-top:30px;
    margin-left:20px;
 }
 .freecreditspaged{
    padding-top:80px;
 }
 
      @keyframes fadeLoading {
        0% {
          opacity: 1;
        }
        25% {
          opacity: 0.5;
        }
        50% {
          opacity: 1;
        }
        75%{
           opacity: 0.5;
         }
        100% {
          opacity: 1;
        }
      }
      .ffweg{
        display:none;
      }
      .legalterms{
        max-width:1040px;
      }
      .logoutclass{
        cursor:pointer;
      }
      .rotatephonegif{
        opacity:0.5;
        width:30%;
      }
      @media screen and (min-width: 1024px) and (max-width:1267px) {
        .threesectionsdashboard {
          transform: scale(0.9);
          transform-origin: top center;
        }}
      @media screen and (max-width: 1024px) and (min-width:767px) {
        .threesectionsdashboard {
          transform: scale(0.8);
          transform-origin: top center;
        }
        .dashboardlanguage{
            width:110px;
            margin-left:-10px;
            transform: scale(0.9)
        }
        .dashboardcialdini{
            width:150px;
            transform: scale(0.9);
            margin-left:-10px;
        }
        .dashboardamount{
            margin-left:40px;
        }
      }

      @media only screen and (max-width: 750px) {
        .threesectionsdashboard {
            transform: scale(0.8);
            transform-origin: top center;
            margin-top:60px;
          }
          .dashboardlanguage{
              width:110px;
              margin-left:-10px;
              transform: scale(0.9)
          }
          .dashboardcialdini{
              width:150px;
              transform: scale(0.9);
              margin-left:-10px;
          }
          .dashboardamount{
              margin-right:-20px;
              margin-left:20px;
              width:180px;
          }
          .bottomleftcorner{
            position:relative;
            float:left;
    margin-top:-15px;
    margin-left:10px;
         }
         .dashboardamountinput{
            width:23px;
        }
        .urldatainput{
            margin-left:0px;
        }
        .mobiletoabove{
            margin-top:-20px!important;
        }
        .downloadbuttondiv{
            margin-top:-140px;
        }
        .dropdown-content{
            margin-left:150px;
        }
        }
